import axios, { AxiosError } from "axios"
import { AnyAction } from "redux"
import { CompanyFormData } from "../../components/CompanyRegister"
import { RootState } from "../rootReducer"
import {
  cleanSIREN,
  SirenCheckResponseType,
  SirenStatus,
} from "../../utils/siren"
import { Dispatch, RNBThunkAction } from "../store.config"

import { ThunkAction } from "redux-thunk"
import {
  DeactivatedDocuments,
  FullDocumentsActionsEnum,
  FullDocumentsActionsType,
  TranslationsAndReasons,
} from "./fullDocuments.ducks"
import { differenceBetweenObjects } from "../../utils/object"
import { GetCompanyMerchantCodesDataThunk } from "./merchantCodes.ducks"
import { CheckCreateWithoutFecThunk } from "./standardLedger.ducks"
import { AccountingSoftware, CompanyLegalForm } from "../../utils/company"
import { CompanySettingsActionsType } from "./companySettings.ducks"
import { GetWritingLabelDataThunk } from "./writingLabels.ducks"
import { UnitLabel } from "../../utils/unit"
import { UnitLabelActionEnum, UnitLabelActionType } from "./unitLabel.ducks"

export const enum CompaniesActionsEnum {
  GET_COMPANIES_ATTEMPT = "COMPANIES/getCompaniesAttempt",
  GET_COMPANIES_SUCCESS = "COMPANIES/getCompaniesSuccess",
  GET_COMPANIES_FAILURE = "COMPANIES/getCompaniesFailure",
  GET_PENDING_BATCHES_AND_WRITINGS_BY_COMPANY_ATTEMPT = "COMPANIES/getPendingArchiveByCompanyAttempt",
  GET_PENDING_BATCHES_AND_WRITINGS_BY_COMPANY_SUCCESS = "COMPANIES/getPendingArchiveByCompanySuccess",
  GET_PENDING_BATCHES_AND_WRITINGS_BY_COMPANY_FAILURE = "COMPANIES/getPendingArchiveByCompanyFailure",
  GET_COMPANIES_ACCESSES_ATTEMPT = "COMPANIES/getCompaniesAccessesAttempt",
  GET_COMPANIES_ACCESSES_SUCCESS = "COMPANIES/getCompaniesAccessesSuccess",
  GET_COMPANIES_ACCESSES_FAILURE = "COMPANIES/getCompaniesAccessesFailure",
  REGISTER_COMPANY_FAILURE = "COMPANIES/registerCompanyFailure",
  REGISTER_COMPANY_FAILURE_STATUS = "COMPANIES/registerCompanyFailureStatus",
  REGISTER_COMPANY_FAILURE_EXISTING_COMPANY = "COMPANIES/registerCompanyFailureExistingCompany",
  REGISTER_COMPANY_FAILURE_RECREATION_COMPANY = "COMPANIES/registerCompanyFailureRecreationCompany",
  REGISTER_COMPANY_FAILURE_NOT_FRENCH_COMPANY = "COMPANIES/registerCompanyFailureNotFrenchCompany",
  REGISTER_COMPANY_FAILURE_FIDUCIARY_DEACTIVATED = "COMPANIES/registerCompanyFailureFiduciaryDeactivated",
  REGISTER_COMPANY_SUCCESS = "COMPANIES/registerCompanySuccess",
  REGISTER_COMPANY_ATTEMPT = "COMPANIES/registerCompanyAttempt",
  REGISTER_COMPANY_RESET_STATUS = "COMPANIES/registerCompanyResetStatus",
  COMPANY_SIREN_SUCCESS = "COMPANY/SIRENSuccess",
  COMPANY_SIREN_HIDDEN = "COMPANY/SIRENHiddenFailure",
  SIREN_FAILURE = "COMPANY/SIRENFailure",
  SIREN_ATTEMPT = "COMPANY/SIRENAttempt",
  COMPANY_DEACTIVATE_SUCCESS = "COMPANY/deactivateSuccess",
  COMPANY_DEACTIVATE_FAILURE = "COMPANY/deactivateFailure",
  COMPANY_DEACTIVATE_ATTEMPT = "COMPANY/deactivateAttempt",
  CAN_DEACTIVATE_STATUS = "COMPANY/canDeactivateStatus",
  UPDATE_DEACTIVATE_COMPANY_REASONS = "COMPANY/updateDeactivateCompanyReasons",
  GET_COMPANY_SUCCESS = "COMPANY/getCompanySuccess",
  GET_COMPANY_ATTEMPT = "COMPANY/getCompanyAttempt",
  GET_COMPANY_FAILURE = "COMPANY/getCompanyFailure",
  RESET_COMPANY_STATUS = "COMPANY/setCompanyStatusForUser",
  GET_COMPANY_SETTINGS_DEACTIVATED_ATTEMPT = "COMPANY/GetCompanyDeactivatedSettingsAttempt",
  GET_COMPANY_SETTINGS_DEACTIVATED_SUCCESS = "COMPANY/GetCompanyDeactivatedSettingsSuccess",
  GET_COMPANY_SETTINGS_DEACTIVATED_FAILURE = "COMPANY/GetCompanyDeactivatedSettingsFailure",
  COMPANY_LOGO_UPDATE_ATTEMPT = "COMPANY/logoUpdateAttempt",
  COMPANY_LOGO_UPDATE_SUCCESS = "COMPANY/logoUpdateSuccess",
  COMPANY_LOGO_UPDATE_FAILURE = "COMPANY/logoUpdateFailure",
  COMPANY_LOGO_DELETE_FAILURE = "COMPANY/logoDeleteFailure",
  COMPANY_LOGO_DELETE_ATTEMPT = "COMPANY/logoDeleteAttempt",
  COMPANY_LOGO_DELETE_SUCCESS = "COMPANY/logoDeleteSuccess",
  GET_COMPANIES_LOGOS_ATTEMPT = "COMPANY/getCompaniesLogosAttempt",
  GET_COMPANIES_LOGOS_SUCCESS = "COMPANY/getCompaniesLogosSuccess",
  GET_COMPANIES_LOGOS_FAILURE = "COMPANY/getCompaniesLogosFailure",
  SAVE_COMPANY_SETTINGS_ATTEMPT = "COMPANY/saveCompanySettingsAttempt",
  SAVE_COMPANY_SETTINGS_SUCCESS = "COMPANY/saveCompanySettingsSuccess",
  SAVE_COMPANY_SETTINGS_FAILURE = "COMPANY/setCompanyStatusForUser",
  UPDATE_COMPANY_INFORMATIONS_ATTEMPT = "COMPANY/updateCompanyInformationsAttempt",
  UPDATE_COMPANY_INFORMATIONS_SUCCESS = "COMPANY/updateCompanyInformationsSuccess",
  UPDATE_COMPANY_INFORMATIONS_FAILURE = "COMPANY/updateCompanyInformationsFailure",
  UPDATE_COMPANY_INFORMATIONS_RESET = "COMPANY/updateCompanyInformationsReset",
  UPDATE_BILL_SETTINGS_ATTEMPT = "COMPANY/updateBankSettingsAttempt",
  UPDATE_BILL_SETTINGS_SUCCESS = "COMPANY/updateBankSettingsSuccess",
  UPDATE_BILL_SETTINGS_FAILURE = "COMPANY/updateBankSettingsFailure",
  UPDATE_DISABLED_CENTRALIZED_CODES_ATTEMPT = "COMPANY/updateDisabledCentralizedCodesAttempt",
  UPDATE_DISABLED_CENTRALIZED_CODES_SUCCESS = "COMPANY/updateDisabledCentralizedCodesSuccess",
  UPDATE_DISABLED_CENTRALIZED_CODES_FAILURE = "COMPANY/updateDisabledCentralizedCodesFailure",
  GET_DEACTIVATED_URL_PREVIEW = "COMPANY/GetDeactivatedUrlPreview",
  RESET_DEACTIVATED_URL_PREVIEW = "COMPANY/ResetDeactivatedUrlPreview",
  UPDATE_NO_FEC = "COMPANY/UpdateNoFec",
  UPDATE_ACD_SENT_DATA_ATTEMPT = "COMPANY/updateAcdSentDataAttemptActionAttempt",
  UPDATE_ACD_SENT_DATA_SUCCESS = "COMPANY/updateAcdSentDataAttemptActionSuccess",
  UPDATE_ACD_SENT_DATA_ERROR = "COMPANY/updateAcdSentDataAttemptActionError",
  UPDATE_ACCOUNTING_SOFTWARE_ATTEMPT = "COMPANY_SETTINGS/UpdateAccountingSoftwareAttempt",
  UPDATE_ACCOUNTING_SOFTWARE_SUCCESS = "COMPANY_SETTINGS/UpdateAccountingSoftwareSuccess",
  UPDATE_ACCOUNTING_SOFTWARE_FAILURE = "COMPANY_SETTINGS/UpdateAccountingSoftwareFailure",
  SET_IS_FAVORITE_ATTEMPT = "COMPANY/SetIsFavoriteAttempt",
  SET_IS_FAVORITE_SUCCESS = "COMPANY/SetIsFavoriteSuccess",
  SET_IS_FAVORITE_FAILURE = "COMPANY/SetIsFavoriteFailure",
}

export const getCompaniesAttemptAction = () =>
  ({ type: CompaniesActionsEnum.GET_COMPANIES_ATTEMPT } as const)
export const getCompaniesSuccessAction = (payload: GetUserCompaniesPayload[]) =>
  ({ type: CompaniesActionsEnum.GET_COMPANIES_SUCCESS, payload } as const)
export const getCompaniesFailureAction = () =>
  ({ type: CompaniesActionsEnum.GET_COMPANIES_FAILURE } as const)

export const getPendingBatchesAndWritingsAttemptAction = () =>
  ({
    type: CompaniesActionsEnum.GET_PENDING_BATCHES_AND_WRITINGS_BY_COMPANY_ATTEMPT,
  } as const)
export const getPendingBatchesAndWritingsSuccessAction = (
  payload: PendingBatchesAndWritingsByCompanyResponse[]
) =>
  ({
    type: CompaniesActionsEnum.GET_PENDING_BATCHES_AND_WRITINGS_BY_COMPANY_SUCCESS,
    payload,
  } as const)
export const getPendingBatchesAndWritingsFailureAction = () =>
  ({
    type: CompaniesActionsEnum.GET_PENDING_BATCHES_AND_WRITINGS_BY_COMPANY_FAILURE,
  } as const)
export const getCompaniesAccessesAttemptAction = () =>
  ({ type: CompaniesActionsEnum.GET_COMPANIES_ACCESSES_ATTEMPT } as const)
export const getCompaniesAccessesSuccessAction = (
  payload: CompanyAccessesPayload
) =>
  ({
    type: CompaniesActionsEnum.GET_COMPANIES_ACCESSES_SUCCESS,
    payload,
  } as const)
export const getCompaniesAccessesFailureAction = () =>
  ({ type: CompaniesActionsEnum.GET_COMPANIES_ACCESSES_FAILURE } as const)

const registerCompanyAttempt = () =>
  ({ type: CompaniesActionsEnum.REGISTER_COMPANY_ATTEMPT } as const)
const registerCompanyFailure = (error: AxiosError) =>
  ({
    type: CompaniesActionsEnum.REGISTER_COMPANY_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: error.name,
      bodyKey: error.message,
    },
  } as const)
const registerCompanyFailureStatus = () =>
  ({
    type: CompaniesActionsEnum.REGISTER_COMPANY_FAILURE_STATUS,
  } as const)
const registerCompanyFailureExistingCompany = () =>
  ({
    type: CompaniesActionsEnum.REGISTER_COMPANY_FAILURE_EXISTING_COMPANY,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company-register.siren.error.siren-taken.title",
      bodyKey: "company-register.siren.error.siren-taken.message",
    },
  } as const)

const registerCompanyFailureRecreationCompany = () =>
  ({
    type: CompaniesActionsEnum.REGISTER_COMPANY_FAILURE_RECREATION_COMPANY,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company-register.siren.error.siren-recreation.title",
      bodyKey: "company-register.siren.error.siren-recreation.message",
    },
  } as const)

const registerCompanyFailureNotFrenchCompany = () =>
  ({
    type: CompaniesActionsEnum.REGISTER_COMPANY_FAILURE_NOT_FRENCH_COMPANY,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company-register.siren.error.siren-not-french.title",
      bodyKey: "company-register.siren.error.siren-not-french.message",
    },
  } as const)

const registerCompanyFailureSirenFormat = () =>
  ({
    type: CompaniesActionsEnum.REGISTER_COMPANY_FAILURE_NOT_FRENCH_COMPANY,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company-register.siren.error.siren.format-error.title",
      bodyKey: "company-register.siren.error.siren.format-error.message",
    },
  } as const)

const registerCompanyFailureFiduciaryDeactivated = () =>
  ({
    type: CompaniesActionsEnum.REGISTER_COMPANY_FAILURE_FIDUCIARY_DEACTIVATED,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company-register.siren.error.siren.format-error.title",
      bodyKey: "company-register.error.fiduciary_deactivated.message",
    },
  } as const)

const registerCompanySuccess = (payload: CompanyPayload) =>
  ({ type: CompaniesActionsEnum.REGISTER_COMPANY_SUCCESS, payload } as const)

export const registerCompanyResetStatus = () =>
  ({ type: CompaniesActionsEnum.REGISTER_COMPANY_RESET_STATUS } as const)

export const companySirenSuccessAction = (company: CompanyObjectType) =>
  ({
    type: CompaniesActionsEnum.COMPANY_SIREN_SUCCESS,
    payload: { company },
  } as const)
export const sirenFailureAction = () =>
  ({ type: CompaniesActionsEnum.SIREN_FAILURE } as const)
const companySirenHiddenAction = () =>
  ({ type: CompaniesActionsEnum.COMPANY_SIREN_HIDDEN } as const)
export const sirenCompanyAttemptAction = () =>
  ({ type: CompaniesActionsEnum.SIREN_ATTEMPT } as const)

const deactivateSuccess = (id: number) =>
  ({
    type: CompaniesActionsEnum.COMPANY_DEACTIVATE_SUCCESS,
    id,
  } as const)

const deactivateFailure = () =>
  ({
    type: CompaniesActionsEnum.COMPANY_DEACTIVATE_FAILURE,
  } as const)

const deactivateAttempt = () =>
  ({
    type: CompaniesActionsEnum.COMPANY_DEACTIVATE_ATTEMPT,
  } as const)

export const canDeactivateStatus = (stat: boolean) =>
  ({
    type: CompaniesActionsEnum.CAN_DEACTIVATE_STATUS,
    stat,
  } as const)

export const updateDeactivateCompanyReasons = (reasons: Array<string>) =>
  ({
    type: CompaniesActionsEnum.UPDATE_DEACTIVATE_COMPANY_REASONS,
    reasons,
  } as const)

const getCompanySuccess = (
  payload: CompanyData,
  companyStatusForUser: CompanyStatus
) =>
  ({
    type: CompaniesActionsEnum.GET_COMPANY_SUCCESS,
    payload,
    companyStatusForUser,
  } as const)
const getCompanyAttempt = () =>
  ({
    type: CompaniesActionsEnum.GET_COMPANY_ATTEMPT,
  } as const)
const getCompanyFailure = (companyStatusForUser: CompanyStatus) =>
  ({
    type: CompaniesActionsEnum.GET_COMPANY_FAILURE,
    companyStatusForUser,
  } as const)
export const companyLogoUpdateAttemptAction = () =>
  ({ type: CompaniesActionsEnum.COMPANY_LOGO_UPDATE_ATTEMPT } as const)
export const companyLogoUpdateSuccessAction = (url: string) =>
  ({
    type: CompaniesActionsEnum.COMPANY_LOGO_UPDATE_SUCCESS,
    url,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "company-register.update-logo-success.title",
      bodyKey: "company-register.update-logo-success.body",
    },
  } as const)
export const companyLogoUpdateFailureAction = () =>
  ({ type: CompaniesActionsEnum.COMPANY_LOGO_UPDATE_FAILURE } as const)

export const logoDeleteAttempt = () =>
  ({ type: CompaniesActionsEnum.COMPANY_LOGO_DELETE_ATTEMPT } as const)
export const logoDeleteSuccess = () =>
  ({
    type: CompaniesActionsEnum.COMPANY_LOGO_DELETE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "company-register.delete-logo-success.title",
      bodyKey: "company-register.delete-logo-success.body",
    },
  } as const)
export const logoDeleteFailure = () =>
  ({ type: CompaniesActionsEnum.COMPANY_LOGO_DELETE_FAILURE } as const)

export const GetCompanyDeactivatedSettingsAttempt = () =>
  ({
    type: CompaniesActionsEnum.GET_COMPANY_SETTINGS_DEACTIVATED_ATTEMPT,
  } as const)

export const GetCompanyDeactivatedSettingsSuccess = (payload: {
  id: number
  deactivatedSettings: DeactivatedSettings
}) =>
  ({
    type: CompaniesActionsEnum.GET_COMPANY_SETTINGS_DEACTIVATED_SUCCESS,
    payload,
  } as const)

export const GetCompanyDeactivatedSettingsFailure = () =>
  ({
    type: CompaniesActionsEnum.GET_COMPANY_SETTINGS_DEACTIVATED_FAILURE,
  } as const)
export const getCompanyLogoAttemptAction = () =>
  ({ type: CompaniesActionsEnum.GET_COMPANIES_LOGOS_ATTEMPT } as const)
export const getCompanyLogoSuccessAction = (payload: CompaniesLogos) =>
  ({ type: CompaniesActionsEnum.GET_COMPANIES_LOGOS_SUCCESS, payload } as const)
export const getCompanyLogoFailureAction = (error: AxiosError) =>
  ({ type: CompaniesActionsEnum.GET_COMPANIES_LOGOS_FAILURE, error } as const)
export const saveCompanySettingsAttempt = () =>
  ({ type: CompaniesActionsEnum.SAVE_COMPANY_SETTINGS_ATTEMPT } as const)
export const saveCompanySettingsSuccess = () =>
  ({
    type: CompaniesActionsEnum.SAVE_COMPANY_SETTINGS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "office-company-settings.toaster-success.title",
      bodyKey: "office-company-settings.toaster-success.body",
    },
  } as const)
export const saveCompanySettingsFailure = () =>
  ({ type: CompaniesActionsEnum.SAVE_COMPANY_SETTINGS_FAILURE } as const)
export const setCompanyStatusForUser = () =>
  ({ type: CompaniesActionsEnum.RESET_COMPANY_STATUS } as const)
export const updateCompanyInformationsAttempt = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_COMPANY_INFORMATIONS_ATTEMPT,
  } as const)
export const updateCompanyInformationsSuccess = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_COMPANY_INFORMATIONS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "company.informations.toast.success.title",
      bodyKey: "company.informations.toast.success.body",
    },
  } as const)
export const updateCompanyInformationsFailure = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_COMPANY_INFORMATIONS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company.informations.toast.error.title",
      bodyKey: "company.informations.toast.error.body",
    },
  } as const)
export const updateCompanyInformationsReset = () =>
  ({ type: CompaniesActionsEnum.UPDATE_COMPANY_INFORMATIONS_RESET } as const)
export const updateBankSettingsAttempt = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_BILL_SETTINGS_ATTEMPT,
  } as const)
export const updateBankSettingsSuccess = (
  payload: UpdateCompanyResponsePayload
) =>
  ({
    type: CompaniesActionsEnum.UPDATE_BILL_SETTINGS_SUCCESS,
    payload,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "company.update-settings-success.title",
      bodyKey: "company.update-settings-success.body",
    },
  } as const)
export const updateBankSettingsFailure = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_BILL_SETTINGS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company.informations.toast.error.title",
      bodyKey: "company.informations.toast.error.body",
    },
  } as const)
export const updateDisabledCentralizedCodesAttempt = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_DISABLED_CENTRALIZED_CODES_ATTEMPT,
  } as const)
export const updateDisabledCentralizedCodesSuccess = (
  companyId: number,
  disable_centralized_codes: boolean
) =>
  ({
    type: CompaniesActionsEnum.UPDATE_DISABLED_CENTRALIZED_CODES_SUCCESS,
    companyId,
    disable_centralized_codes,
  } as const)
export const updateDisabledCentralizedCodesFailure = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_DISABLED_CENTRALIZED_CODES_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey:
        "accounting-plan.tooltip.disabled-centralized-codes.error-title",
      bodyKey: "accounting-plan.tooltip.disabled-centralized-codes.error-body",
    },
  } as const)
export const GetDeactivatedUrlPreview = (
  urlDeactivatedPreview: string,
  companyId: number,
  fullDocId: number
) =>
  ({
    type: CompaniesActionsEnum.GET_DEACTIVATED_URL_PREVIEW,
    urlDeactivatedPreview,
    companyId,
    fullDocId,
  } as const)
export const ResetDeactivatedUrlPreview = (companyId: number) =>
  ({
    type: CompaniesActionsEnum.RESET_DEACTIVATED_URL_PREVIEW,
    companyId,
  } as const)

export const UpdateNoFec = (companyId: number) =>
  ({
    type: CompaniesActionsEnum.UPDATE_NO_FEC,
    companyId,
  } as const)

export const updateAcdSentDataAttemptAction = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_ACD_SENT_DATA_ATTEMPT,
  } as const)

export const updateAcdSentDataSuccessAction = ({
  companyId,
  acdApiDueDate,
  acdApiDocumentReference,
}: {
  companyId: number
  acdApiDueDate: boolean
  acdApiDocumentReference: boolean
}) =>
  ({
    type: CompaniesActionsEnum.UPDATE_ACD_SENT_DATA_SUCCESS,
    payload: { companyId, acdApiDueDate, acdApiDocumentReference },
  } as const)

export const updateAcdSentDataErrorAction = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_ACD_SENT_DATA_ERROR,
  } as const)

export const UpdateAccountingSoftwareAttempt = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_ACCOUNTING_SOFTWARE_ATTEMPT,
  } as const)
export const UpdateAccountingSoftwareSuccess = (
  companyId: number,
  newSoftware: string,
  prefixBuy: string,
  prefixSell: string,
  reference: string
) =>
  ({
    type: CompaniesActionsEnum.UPDATE_ACCOUNTING_SOFTWARE_SUCCESS,
    companyId,
    newSoftware,
    prefixBuy,
    prefixSell,
    reference,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "office-company-change-software.tooltip.success.title",
      bodyKey: "office-company-change-software.tooltip.success.message",
    },
  } as const)
export const UpdateAccountingSoftwareFailure = () =>
  ({
    type: CompaniesActionsEnum.UPDATE_ACCOUNTING_SOFTWARE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "office-company-change-software.tooltip.error.title",
      bodyKey: "office-company-change-software.tooltip.error.message",
    },
  } as const)

export const SetIsFavoriteAttempt = () =>
  ({
    type: CompaniesActionsEnum.SET_IS_FAVORITE_ATTEMPT,
  } as const)
export const SetIsFavoriteSuccess = (companyId: number, isFavorite: boolean) =>
  ({
    type: CompaniesActionsEnum.SET_IS_FAVORITE_SUCCESS,
    companyId,
    isFavorite,
  } as const)
export const SetIsFavoriteFailure = () =>
  ({
    type: CompaniesActionsEnum.SET_IS_FAVORITE_FAILURE,
  } as const)

type CompaniesActionsType = ReturnType<
  | typeof getCompaniesSuccessAction
  | typeof getCompaniesAttemptAction
  | typeof getCompaniesFailureAction
  | typeof getPendingBatchesAndWritingsSuccessAction
  | typeof getPendingBatchesAndWritingsAttemptAction
  | typeof getPendingBatchesAndWritingsFailureAction
  | typeof getCompaniesAccessesSuccessAction
  | typeof getCompaniesAccessesFailureAction
  | typeof getCompaniesAccessesAttemptAction
  | typeof registerCompanyAttempt
  | typeof registerCompanyFailure
  | typeof registerCompanyFailureStatus
  | typeof registerCompanyFailureExistingCompany
  | typeof registerCompanyFailureRecreationCompany
  | typeof registerCompanyFailureNotFrenchCompany
  | typeof registerCompanySuccess
  | typeof registerCompanyResetStatus
  | typeof companySirenSuccessAction
  | typeof companySirenHiddenAction
  | typeof sirenFailureAction
  | typeof sirenCompanyAttemptAction
  | typeof deactivateSuccess
  | typeof deactivateFailure
  | typeof deactivateAttempt
  | typeof canDeactivateStatus
  | typeof updateDeactivateCompanyReasons
  | typeof getCompanyAttempt
  | typeof getCompanyFailure
  | typeof getCompanySuccess
  | typeof setCompanyStatusForUser
  | typeof GetCompanyDeactivatedSettingsAttempt
  | typeof GetCompanyDeactivatedSettingsFailure
  | typeof GetCompanyDeactivatedSettingsSuccess
  | typeof getCompanyLogoAttemptAction
  | typeof getCompanyLogoSuccessAction
  | typeof getCompanyLogoFailureAction
  | typeof companyLogoUpdateAttemptAction
  | typeof companyLogoUpdateSuccessAction
  | typeof companyLogoUpdateFailureAction
  | typeof logoDeleteAttempt
  | typeof logoDeleteSuccess
  | typeof logoDeleteFailure
  | typeof saveCompanySettingsAttempt
  | typeof saveCompanySettingsSuccess
  | typeof saveCompanySettingsFailure
  | typeof updateCompanyInformationsAttempt
  | typeof updateCompanyInformationsSuccess
  | typeof updateCompanyInformationsFailure
  | typeof updateCompanyInformationsReset
  | typeof updateBankSettingsAttempt
  | typeof updateBankSettingsSuccess
  | typeof updateBankSettingsFailure
  | typeof updateDisabledCentralizedCodesAttempt
  | typeof updateDisabledCentralizedCodesSuccess
  | typeof updateDisabledCentralizedCodesFailure
  | typeof GetDeactivatedUrlPreview
  | typeof ResetDeactivatedUrlPreview
  | typeof UpdateNoFec
  | typeof updateAcdSentDataAttemptAction
  | typeof updateAcdSentDataSuccessAction
  | typeof updateAcdSentDataErrorAction
  | typeof UpdateAccountingSoftwareAttempt
  | typeof UpdateAccountingSoftwareSuccess
  | typeof UpdateAccountingSoftwareFailure
  | typeof SetIsFavoriteAttempt
  | typeof SetIsFavoriteSuccess
  | typeof SetIsFavoriteFailure
>

export interface CompanyObjectType {
  siren: null | string
  sirenState: SirenStatus
  name: string
  code_ape: null | string
  address: string
  ape_details: string
  city: string
  country: string
  country_code: string
  full_legal_form: string
  legal_form: string | null
  max_work_force: number
  min_work_force: number
  postal_code: string
  share_capital: string | null
  siege_siret: string
  vat_number: string
  work_force: string
}

interface CompanyPayload {
  id: number
  name: string
}

interface CompanyAccessesPayload {
  [index: number]: {
    customer_count: number
    collaborator_count: number
    is_favorite: boolean
  }
}

type UploadStatus =
  | "idle"
  | "start"
  | "confirm"
  | "pending"
  | "success"
  | "error"

export interface Companies {
  [index: string]: Company
}

export interface CompaniesState {
  companies: Companies
  fec: {
    uploadProgress: number
    uploadStatus: UploadStatus
  }
  registerCompanyStatus: "LOADING" | "SUCCESS" | "ERROR" | null
  code_ape: string
  name: string
  siren: string
  sirenCheck: "CHECKING" | "VALIDATED" | "INVALID" | "UNCHECKED"
  status: "UNREGISTERED" | "INVALID" | "SUCCESS"
  sirenState: SirenStatus
  companiesLogos: CompaniesLogos
  newCompanyId?: number
  hidden_from_team?: boolean
  companyStatusForUser: CompanyStatus
  updateCompanyInformationsStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  getCompaniesLogosStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  canDeactivateCurrentCompanyStatus: boolean
  deactiveCompanyReasons: Array<string>
  acd_api_due_date: boolean
  acd_api_document_reference: boolean
  address: string
  ape_details: string
  city: string
  country: string
  country_code: string
  full_legal_form: string
  legal_form: string | null
  max_work_force: number
  min_work_force: number
  postal_code: string
  share_capital: string | null
  siege_siret: string
  vat_number: string
  work_force: string
}

export interface Company {
  name: string
  deactivatedSettings?: DeactivatedSettings | null
  id: number
  user_company_name?: string | null
  email_capture?: string
  collaborator_count?: number
  customer_count?: number
  is_favorite?: boolean
  fiscal_years?: []
  standard_ledgers?: LedgerType[]
  deactivatedCompany?: "UNSET" | "SUCCESS" | "ERROR"
  accounting_software?: AccountingSoftware
  accounting_software_reference?: null | string
  hidden_from_team?: boolean
  deactivatedDocuments?: DeactivatedDocuments
  translationsAndReasons?: TranslationsAndReasons
  informations?: InformationsData
  readyToGenerate?: boolean
  readyToDownload?: number
  depositInReview?: boolean
  specialMentions?: string
  specialDiscount?: number
  paymentPeriod?: number
  iban?: string
  bic?: string
  bankAccountOwner?: string
  is_onboarded?: boolean
  is_connected_to_api?: boolean
  auxiliary?: boolean
  buy_auxiliary_prefix?: string
  sell_auxiliary_prefix?: string
  disable_centralized_codes?: boolean
  no_fec?: boolean
  buy_third_party_account_id?: number
  sell_third_party_account_id?: number
  scratch_games_paid_lots_goods_transfer_account_id?: number | null
  acd_api_due_date?: boolean
  acd_api_document_reference?: boolean
  disable_vat_accounts?: boolean
  accounting_type?: "accrual_accounting" | "cash_accounting"
  unit_labels?: UnitLabel[]
  marked_as_farmer_company_at?: string | null
}

export const CompanyStub: Company = {
  name: "",
  deactivatedSettings: null,
  id: 0,
  accounting_software: undefined,
  accounting_software_reference: null,
  readyToGenerate: false,
  readyToDownload: 0,
  depositInReview: false,
  is_onboarded: false,
}

export const companiesInitialState: CompaniesState = {
  companies: {},
  fec: {
    uploadProgress: 0,
    uploadStatus: "idle",
  },
  registerCompanyStatus: null,
  code_ape: "",
  siren: "",
  sirenCheck: "UNCHECKED",
  status: "UNREGISTERED",
  name: "",
  sirenState: null,
  companiesLogos: {},
  hidden_from_team: false,
  companyStatusForUser: "CHECKING",
  updateCompanyInformationsStatus: "ATTEMPT",
  getCompaniesLogosStatus: "UNSET",
  canDeactivateCurrentCompanyStatus: false,
  deactiveCompanyReasons: [],
  acd_api_due_date: false,
  acd_api_document_reference: true,
  address: "",
  ape_details: "",
  city: "",
  country: "",
  country_code: "",
  full_legal_form: "",
  legal_form: "",
  max_work_force: 0,
  min_work_force: 0,
  postal_code: "",
  share_capital: null,
  siege_siret: "",
  vat_number: "",
  work_force: "",
}

export interface InformationsData {
  postalCode: number
  address: string
  city: string
  siren: number
  legalForm: CompanyLegalForm
  cityRegistrationRCS: string
  shareCapital: number | null
  useCityRegistrationRCS: boolean
  paymentPeriod: number
  phone?: string | null
  email?: string | null
}

export type CompanyStatus = "CHECKING" | "FOUND" | "NOT_FOUND" | "FORBIDDEN"

export interface CompanyData {
  standard_ledgers: []
  fiscal_years: []
  informations: {
    postalCode: 0
    address: ""
    city: ""
    siren: 0
    legalForm: ""
    cityRegistrationRCS: ""
    shareCapital: 0
    useCityRegistrationRCS: false
    paymentPeriod: 30
  }
  company: {
    id: number
    company_name: string
    user_company_name: string | null
    accounting_software?: string
    accounting_software_reference?: null | string
    hidden_from_team: string
    auxiliary?: boolean
    buy_auxiliary_prefix?: string
    sell_auxiliary_prefix?: string
    disable_centralized_codes?: boolean
    no_fec: boolean
    buy_third_party_account_id: number
    sell_third_party_account_id: number
    acd_api_due_date: boolean
    acd_api_document_reference: boolean
    disable_vat_accounts: boolean
    accounting_type: "accrual_accounting" | "cash_accounting"
    scratch_games_paid_lots_goods_transfer_account_id: number | null
    unit_labels: UnitLabel[]
    marked_as_farmer_company_at: string
  }
  is_onboarded: boolean
  is_connected_to_api: boolean
}

const updateOrInitializeCompany = (
  companies: Companies,
  companyPayload: CompanyPayload
) => {
  const existing_company = companies[companyPayload.id]
  return existing_company
    ? {
        ...companies,
        [companyPayload.id]: { ...existing_company, ...companyPayload },
      }
    : { ...companies, [companyPayload.id]: companyPayload }
}

export function companiesReducer(
  state = companiesInitialState,
  action: CompaniesActionsType | FullDocumentsActionsType | UnitLabelActionType
): CompaniesState {
  switch (action.type) {
    case CompaniesActionsEnum.GET_PENDING_BATCHES_AND_WRITINGS_BY_COMPANY_ATTEMPT: {
      return {
        ...state,
      }
    }
    case CompaniesActionsEnum.GET_PENDING_BATCHES_AND_WRITINGS_BY_COMPANY_SUCCESS: {
      const companies = { ...state.companies }

      for (const data of action.payload) {
        if (!companies[data.id.toString()]) {
          continue
        }
        companies[data.id.toString()] = {
          ...companies[data.id.toString()],
          readyToDownload: data.ready_to_download_archives,
          readyToGenerate: data.ready_to_generate,
          depositInReview: data.deposit_in_review,
        }
      }

      return { ...state, companies: companies }
    }
    case CompaniesActionsEnum.GET_PENDING_BATCHES_AND_WRITINGS_BY_COMPANY_FAILURE: {
      return {
        ...state,
      }
    }
    case CompaniesActionsEnum.GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: action.payload.reduce(
          updateOrInitializeCompany,
          state.companies
        ),
      }
    }
    case CompaniesActionsEnum.GET_COMPANIES_ACCESSES_SUCCESS: {
      const companies = { ...state.companies }

      for (const key in action.payload) {
        if (!companies[key.toString()]) {
          continue
        }
        companies[key.toString()] = {
          ...companies[key.toString()],
          collaborator_count: action.payload[key].collaborator_count,
          customer_count: action.payload[key].customer_count,
          is_favorite: action.payload[key].is_favorite,
        }
      }

      return { ...state, companies: companies }
    }
    case CompaniesActionsEnum.REGISTER_COMPANY_ATTEMPT:
      return { ...state, registerCompanyStatus: "LOADING" }
    case CompaniesActionsEnum.REGISTER_COMPANY_SUCCESS:
      return {
        ...state,
        registerCompanyStatus: "SUCCESS",
        newCompanyId: action.payload.id,
      }
    case CompaniesActionsEnum.REGISTER_COMPANY_RESET_STATUS:
      return {
        ...state,
        registerCompanyStatus: null,
        code_ape: "",
        siren: "",
        sirenCheck: "UNCHECKED",
        status: "UNREGISTERED",
        name: "",
        sirenState: null,
      }
    case CompaniesActionsEnum.REGISTER_COMPANY_FAILURE_STATUS:
      return { ...state, registerCompanyStatus: "ERROR" }
    case CompaniesActionsEnum.SIREN_ATTEMPT:
      return { ...state, sirenCheck: "CHECKING" }
    case CompaniesActionsEnum.COMPANY_SIREN_SUCCESS:
      return {
        ...state,
        sirenCheck: "VALIDATED",
        siren: action.payload.company.siren || "",
        code_ape: action.payload.company.code_ape || "",
        name: action.payload.company.name,
        sirenState: "ok",
        address: action.payload.company.address,
        ape_details: action.payload.company.ape_details,
        city: action.payload.company.city,
        country: action.payload.company.country,
        country_code: action.payload.company.country_code,
        full_legal_form: action.payload.company.full_legal_form,
        legal_form: action.payload.company.legal_form,
        max_work_force: action.payload.company.max_work_force,
        min_work_force: action.payload.company.min_work_force,
        postal_code: action.payload.company.postal_code,
        share_capital: action.payload.company.share_capital,
        siege_siret: action.payload.company.siege_siret,
        vat_number: action.payload.company.vat_number,
        work_force: action.payload.company.work_force,
      }
    case CompaniesActionsEnum.SIREN_FAILURE:
      return { ...state, sirenCheck: "INVALID", sirenState: "not found" }
    case CompaniesActionsEnum.COMPANY_SIREN_HIDDEN:
      return { ...state, sirenCheck: "INVALID", sirenState: "hidden" }
    case CompaniesActionsEnum.COMPANY_DEACTIVATE_SUCCESS: {
      const company = {
        ...state.companies[action.id],
        deactivatedCompany: "SUCCESS",
      }
      const companies = { ...state.companies, [action.id]: company }
      return { ...state, companies: companies }
    }
    case CompaniesActionsEnum.CAN_DEACTIVATE_STATUS: {
      return { ...state, canDeactivateCurrentCompanyStatus: action.stat }
    }
    case CompaniesActionsEnum.UPDATE_DEACTIVATE_COMPANY_REASONS: {
      return { ...state, deactiveCompanyReasons: action.reasons }
    }
    case CompaniesActionsEnum.RESET_COMPANY_STATUS: {
      return { ...state, companyStatusForUser: "CHECKING" }
    }
    case CompaniesActionsEnum.GET_COMPANY_ATTEMPT: {
      return { ...state, companyStatusForUser: "CHECKING" }
    }
    case CompaniesActionsEnum.GET_COMPANY_SUCCESS: {
      const company = {
        ...state.companies[action.payload.company.id],
        name:
          action.payload.company.user_company_name ||
          action.payload.company.company_name,
        accounting_software: action.payload.company.accounting_software,
        user_company_name: action.payload.company.user_company_name,
        standard_ledgers: action.payload.standard_ledgers,
        fiscal_years: action.payload.fiscal_years,
        hidden_from_team: action.payload.company.hidden_from_team,
        accounting_software_reference:
          action.payload.company.accounting_software_reference,
        is_onboarded: action.payload.is_onboarded,
        is_connected_to_api: action.payload.is_connected_to_api,
        auxiliary: action.payload.company.auxiliary,
        buy_auxiliary_prefix: action.payload.company.buy_auxiliary_prefix,
        sell_auxiliary_prefix: action.payload.company.sell_auxiliary_prefix,
        disable_centralized_codes:
          action.payload.company.disable_centralized_codes,
        id: action.payload.company.id,
        no_fec: action.payload.company.no_fec,
        buy_third_party_account_id:
          action.payload.company.buy_third_party_account_id,
        sell_third_party_account_id:
          action.payload.company.sell_third_party_account_id,
        acd_api_due_date: action.payload.company.acd_api_due_date,
        acd_api_document_reference:
          action.payload.company.acd_api_document_reference,
        disable_vat_accounts: action.payload.company.disable_vat_accounts,
        accounting_type: action.payload.company.accounting_type,
        scratch_games_paid_lots_goods_transfer_account_id:
          action.payload.company
            .scratch_games_paid_lots_goods_transfer_account_id,
        marked_as_farmer_company_at:
          action.payload.company.marked_as_farmer_company_at,
      }

      const companies = {
        ...state.companies,
        [action.payload.company.id]: company,
      }
      return {
        ...state,
        companies: companies,
        companyStatusForUser: action.companyStatusForUser,
      }
    }
    case CompaniesActionsEnum.GET_COMPANY_FAILURE: {
      return { ...state, companyStatusForUser: action.companyStatusForUser }
    }
    case CompaniesActionsEnum.GET_COMPANY_SETTINGS_DEACTIVATED_ATTEMPT:
      return { ...state }
    case CompaniesActionsEnum.GET_COMPANY_SETTINGS_DEACTIVATED_SUCCESS: {
      const company = {
        ...CompanyStub,
        ...state.companies[action.payload.id],
        deactivatedSettings: action.payload.deactivatedSettings,
      }
      const companies = { ...state.companies, [action.payload.id]: company }
      return { ...state, companies: companies }
    }
    case CompaniesActionsEnum.GET_COMPANIES_LOGOS_ATTEMPT:
      return {
        ...state,
        getCompaniesLogosStatus: "ATTEMPT",
      }
    case CompaniesActionsEnum.GET_COMPANIES_LOGOS_FAILURE:
      return {
        ...state,
        getCompaniesLogosStatus: "FAILURE",
      }
    case CompaniesActionsEnum.GET_COMPANIES_LOGOS_SUCCESS:
      return {
        ...state,
        companiesLogos: action.payload,
        getCompaniesLogosStatus: "SUCCESS",
      }
    case FullDocumentsActionsEnum.GET_DEACTIVATED_DOCUMENTS_SUCCESS: {
      const company = {
        ...state.companies[action.payload.id],
        deactivatedDocuments: action.payload.deactivatedData,
      }
      const companies = { ...state.companies, [action.payload.id]: company }
      return { ...state, companies: companies }
    }
    case FullDocumentsActionsEnum.DEACTIVATE_FULL_DOCUMENT_SUCCESS:
      const { companyId, fiscalYearId, documentIds } = action.payload
      const company = state.companies[companyId]
      const deactivatedDocuments = company.deactivatedDocuments

      const updatedFiscalYears =
        deactivatedDocuments?.deactivatedDocumentsByFiscalYear.map((fy) => {
          if (fiscalYearId && fy.fiscal_year_id === fiscalYearId) {
            return {
              ...fy,
              full_documents: fy.full_documents.filter(
                (fd) => !documentIds.includes(fd.id)
              ),
            }
          }
          return fy
        })

      return {
        ...state,
        companies: {
          ...state.companies,
          [companyId]: {
            ...company,
            deactivatedDocuments: {
              ...deactivatedDocuments,
              deactivatedDocumentsByFiscalYear: updatedFiscalYears,
            },
          },
        },
      }
    case CompaniesActionsEnum.UPDATE_COMPANY_INFORMATIONS_ATTEMPT: {
      return { ...state, updateCompanyInformationsStatus: "ATTEMPT" }
    }
    case CompaniesActionsEnum.UPDATE_COMPANY_INFORMATIONS_SUCCESS: {
      return { ...state, updateCompanyInformationsStatus: "SUCCESS" }
    }
    case CompaniesActionsEnum.UPDATE_COMPANY_INFORMATIONS_FAILURE: {
      return { ...state, updateCompanyInformationsStatus: "FAILURE" }
    }
    case CompaniesActionsEnum.UPDATE_COMPANY_INFORMATIONS_RESET: {
      return { ...state, updateCompanyInformationsStatus: "UNSET" }
    }
    case CompaniesActionsEnum.UPDATE_BILL_SETTINGS_ATTEMPT: {
      return { ...state, updateCompanyInformationsStatus: "ATTEMPT" }
    }
    case CompaniesActionsEnum.UPDATE_BILL_SETTINGS_SUCCESS: {
      return {
        ...state,
        companies: {
          ...state.companies,
          [action.payload.companyId]: {
            ...state.companies[action.payload.companyId],
            iban: action.payload.company.iban,
            bic: action.payload.company.bic,
            bankAccountOwner: action.payload.company.bank_account_owner,
            specialMentions: action.payload.company.special_mentions,
            specialDiscount: action.payload.company.special_discount,
            paymentPeriod: action.payload.company.payment_period,
          },
        },
        updateCompanyInformationsStatus: "SUCCESS",
      }
    }
    case CompaniesActionsEnum.UPDATE_BILL_SETTINGS_FAILURE: {
      return { ...state, updateCompanyInformationsStatus: "FAILURE" }
    }
    case CompaniesActionsEnum.UPDATE_DISABLED_CENTRALIZED_CODES_SUCCESS: {
      return {
        ...state,
        companies: {
          ...state.companies,
          [action.companyId]: {
            ...state.companies[action.companyId],
            disable_centralized_codes: action.disable_centralized_codes,
          },
        },
      }
    }
    case CompaniesActionsEnum.GET_DEACTIVATED_URL_PREVIEW: {
      const company = {
        ...state.companies[action.companyId],
        deactivatedDocuments: {
          ...state.companies[action.companyId].deactivatedDocuments,
          urlDeactivatedPreview: {
            fullDocId: action.fullDocId,
            url: action.urlDeactivatedPreview,
          },
        },
      }
      const companies = { ...state.companies, [action.companyId]: company }
      return { ...state, companies: companies }
    }
    case CompaniesActionsEnum.RESET_DEACTIVATED_URL_PREVIEW: {
      const company = {
        ...state.companies[action.companyId],
        deactivatedDocuments: {
          ...state.companies[action.companyId].deactivatedDocuments,
          urlDeactivatedPreview: null,
        },
      }
      const companies = { ...state.companies, [action.companyId]: company }
      return { ...state, companies: companies }
    }
    case CompaniesActionsEnum.UPDATE_NO_FEC: {
      const company = {
        ...state.companies[action.companyId],
        no_fec: true,
      }
      const companies = { ...state.companies, [action.companyId]: company }
      return { ...state, companies: companies }
    }

    case CompaniesActionsEnum.UPDATE_ACD_SENT_DATA_SUCCESS: {
      const company = {
        ...state.companies[action.payload.companyId],
        acd_api_due_date: action.payload.acdApiDueDate,
        acd_api_document_reference: action.payload.acdApiDocumentReference,
      }
      const companies = {
        ...state.companies,
        [action.payload.companyId]: company,
      }
      return { ...state, companies: companies }
    }

    case CompaniesActionsEnum.UPDATE_ACCOUNTING_SOFTWARE_SUCCESS: {
      const company = {
        ...state.companies[action.companyId],
        accounting_software: action.newSoftware,
        buy_auxiliary_prefix: action.prefixBuy,
        sell_auxiliary_prefix: action.prefixSell,
        accounting_software_reference: action.reference,
      }
      const companies = {
        ...state.companies,
        [action.companyId]: company,
      }
      return { ...state, companies: companies }
    }
    case CompaniesActionsEnum.SET_IS_FAVORITE_SUCCESS: {
      const company = {
        ...state.companies[action.companyId],
        is_favorite: action.isFavorite,
      }
      const companies = {
        ...state.companies,
        [action.companyId]: company,
      }
      return { ...state, companies: companies }
    }
    case UnitLabelActionEnum.GET_COMPANY_UNIT_LABELS_SUCCESS: {
      const company = {
        ...state.companies[action.companyId],
        unit_labels: action.unitLabels,
      }
      const companies = {
        ...state.companies,
        [action.companyId]: company,
      }
      return { ...state, companies: companies }
    }

    default:
      return { ...state }
  }
}

interface UpdateCompanyLogoPayload {
  companyId: number
  originalFileName: string
  file: File
}

interface CompanyLogo {
  active: boolean
  id: number
  company_id: number
  file_name: string
}

interface CompaniesLogos {
  [key: string]: CompanyLogo | null
}

enum DeactivatedSettingEnum {
  advance_payment = "advance_payment",
  dunning = "dunning",
  rent_receipt = "rent_receipt",
  receipts_record = "receipts_record",
  order_form = "order_form",
  fixed_asset = "fixed_asset",
  construction_in_progress = "construction_in_progress",
  duplicate = "duplicate",
}

export type DeactivatedSettingsKey = keyof typeof DeactivatedSettingEnum

export type DeactivatedSettings = {
  [key in DeactivatedSettingsKey]: {
    value: boolean
    text: string
  }
}

export interface IncomingDeactivatedSettings {
  [key: string]: {
    value: string | null
    text: string
  }
}

export const attemptCompanyRegistrationThunk =
  (companyFormData: CompanyFormData) =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    dispatch(registerCompanyAttempt())
    const fiduciary = getState().fiduciary
    const company = getState().companies
    const companyRegistrationURI = `/fiduciaries/${fiduciary.id}/companies`

    const requestsConfig = {
      withCredentials: true,
      maxRedirects: 0,
      headers: {
        authorization: localStorage.getItem("token") || "",
      },
    }

    const requestCompanyPayload = {
      company_name: companyFormData.initialCompanyName,
      user_company_name: companyFormData.companyName,
      code_ape: company.code_ape,
      identification_number: company.siren,
      accounting_software: companyFormData.comptaSoftware.value,
      accounting_software_reference: companyFormData.comptaSoftwareReference,
      hidden_from_team: companyFormData.hiddenFromTeam,
      accounting_type: companyFormData.accountType,
      address: company.address,
      ape_details: company.ape_details,
      city: company.city,
      country: company.country,
      country_code: company.country_code || "FR",
      full_legal_form: company.full_legal_form,
      legal_form: company.legal_form,
      max_work_force: company.max_work_force,
      min_work_force: company.min_work_force,
      postal_code: company.postal_code,
      share_capital: company.share_capital,
      siege_siret: company.siege_siret,
      vat_number: company.vat_number,
      work_force: company.work_force,
    }

    const requestFiscalYearPayload = {
      fiscal_year: {
        begin_exercise: companyFormData.beginExercise,
        end_exercise: companyFormData.endExercise,
      },
    }

    axios
      .post<CompanyPayload>(
        `${companyRegistrationURI}`,
        requestCompanyPayload,
        {
          ...requestsConfig,
        }
      )
      .then((response) => {
        dispatch(registerCompanySuccess(response.data))
        dispatch(CheckCreateWithoutFecThunk(response.data.id))
        return response
      })
      .then((response) => {
        const fiscalYearRegistrationURI = `/fiduciaries/${fiduciary.id}/companies/${response.data.id}/fiscal_years`

        return axios.post(
          `${fiscalYearRegistrationURI}`,
          requestFiscalYearPayload,
          {
            ...requestsConfig,
          }
        )
      })
      .catch((error) => {
        dispatch(registerCompanyFailureStatus())
        if (
          error.response?.data.error.includes(
            "société avec le même SIREN existe déjà"
          )
        ) {
          return dispatch(registerCompanyFailureExistingCompany())
        } else if (
          error.response?.data.error.includes(
            "Vous avez déjà créé cette société"
          )
        ) {
          return dispatch(registerCompanyFailureRecreationCompany())
        } else if (
          error.response?.data.error.includes(
            "Votre société n'est pas française"
          )
        ) {
          return dispatch(registerCompanyFailureNotFrenchCompany())
        } else if (
          error.response?.data.error.includes(
            "Le format du siren n'est pas valide"
          )
        ) {
          return dispatch(registerCompanyFailureSirenFormat())
        } else if (
          error.response?.data.error.includes("Votre fiduciaire est désactivé")
        ) {
          return dispatch(registerCompanyFailureFiduciaryDeactivated())
        } else {
          return dispatch(registerCompanyFailure(error))
        }
      })
  }

export const attemptCompanySirenValidationThunk =
  (siren: string) => (dispatch: Dispatch<CompaniesActionsType>) => {
    dispatch(sirenCompanyAttemptAction())
    const sirenValidationURI = "/companies/siren_to_name"
    axios
      .get<SirenCheckResponseType>(`${sirenValidationURI}`, {
        params: {
          siren: cleanSIREN(siren),
          scope: "company",
        },
      })
      .then((response) => {
        const companyData: CompanyObjectType = response.data
        companyData["siren"] = cleanSIREN(siren)
        dispatch(companySirenSuccessAction(companyData))
      })
      .catch((e) => {
        if (
          typeof e.response.data === "string" &&
          e.response.data.includes("Unité légale non diffusable")
        ) {
          return dispatch(companySirenHiddenAction())
        }
        return dispatch(sirenFailureAction())
      })
  }

interface GetUserCompaniesPayload {
  name: string
  id: number
  email_capture: string
  hidden_from_team: boolean
  informations: {
    postalCode: string
    address: string
    city: string
    siren: string
    legalForm: string
    cityRegistrationRCS: string
    shareCapital: number
    useCityRegistrationRCS: boolean
    phone: string | null
    email: string | null
  }
  standard_ledgers: Array<LedgerType>
}

export const getUserCompaniesThunk =
  () => (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(getCompaniesAttemptAction())

    return axios
      .get<CompaniesResponse[]>(`/users/companies_for_user`, {
        params: { is_mobile_application: false },
      })
      .then(({ data }) => {
        const formattedCompanies = data.map((c) => ({
          name: c.company_name,
          id: c.id,
          email_capture: c.email_capture,
          hidden_from_team: c.hidden_from_team,
          informations: {
            postalCode: c.informations.postal_code,
            address: c.informations.address,
            city: c.informations.city,
            siren: c.informations.siren,
            legalForm: c.informations.legal_form,
            cityRegistrationRCS: c.informations.city_registration_rcs,
            shareCapital: c.informations.share_capital,
            useCityRegistrationRCS: c.informations.use_city_registration_rcs,
            phone: c.informations.phone,
            email: c.informations.email,
          },
          standard_ledgers: c.standard_ledgers,
          specialMentions: c.special_mentions,
          specialDiscount: c.special_discount,
          paymentPeriod: c.payment_period,
          iban: c.iban,
          bic: c.bic,
          bankAccountOwner: c.bank_account_owner,
          no_fec: c.no_fec,
          accounting_software_reference: c.accounting_software_reference,
          accounting_type: c.accounting_type,
          marked_as_farmer_company_at: c.marked_as_farmer_company_at,
        }))
        dispatch(getCompaniesAccessesThunk())
        dispatch(getCompaniesSuccessAction(formattedCompanies))
      })
      .catch(() => {
        dispatch(getCompaniesFailureAction())
      })
  }

interface PendingBatchesAndWritingsByCompanyResponse {
  id: number
  ready_to_download_archives: number
  ready_to_generate: boolean
  deposit_in_review: boolean
}

export const getPendingBatchesAndWritingsByCompany =
  () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getPendingBatchesAndWritingsAttemptAction())
    return axios
      .get<PendingBatchesAndWritingsByCompanyResponse[]>(
        `/users/pending_batches_and_writings`
      )
      .then(({ data }) => {
        dispatch(getPendingBatchesAndWritingsSuccessAction(data))
      })
      .catch(() => {
        dispatch(getPendingBatchesAndWritingsFailureAction())
      })
  }

export const getCompaniesAccessesThunk =
  () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getCompaniesAttemptAction())
    return axios
      .get<CompanyAccessesPayload>(`/users/companies_accesses_for_user`)
      .then(({ data }) => {
        dispatch(getCompaniesAccessesSuccessAction(data))
      })
      .catch(() => {
        dispatch(getCompaniesAccessesFailureAction())
      })
  }

interface CompaniesResponse {
  company_name: string
  id: number
  email_capture: string
  hidden_from_team: boolean
  informations: CompaniesResponseInformations
  standard_ledgers: LedgerType[]
  special_mentions: string | null
  special_discount: number | null
  payment_period: number
  iban: string | null
  bic: string | null
  bank_account_owner: string | null
  no_fec: boolean
  accounting_software_reference: string
  accounting_type: "accrual_accounting" | "cash_accounting"
  marked_as_farmer_company_at: string | null
}

interface CompaniesResponseInformations {
  postal_code: string
  address: string
  city: string
  siren: string
  legal_form: string
  city_registration_rcs: string
  share_capital: number
  use_city_registration_rcs: boolean
  phone: string | null
  email: string | null
}

export interface LedgerType {
  id: number
  confirmed: boolean
  original_file_name: string
  created_at: Date
  confirm_user_id: string
}

export const deactivateCompany =
  (selectedCompany: number, reasons: Array<string>) =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    const state = getState()
    dispatch(deactivateAttempt())

    return axios
      .post<DeactivateCompany>(
        `/fiduciaries/${state.fiduciary.id}/companies/${selectedCompany}/deactivate_company`,
        { user_deactivated: true, reasons: reasons }
      )
      .then(() => {
        dispatch(deactivateSuccess(selectedCompany))
        dispatch(getUserCompaniesThunk())
      })
      .catch(() => {
        dispatch(deactivateFailure())
      })
  }

interface DeactivateCompany {
  response: string
}
export const GetCompanyThunk =
  (selectedCompany: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getCompanyAttempt())

    return axios
      .get<CompanyData>(`/companies/company_for_user`, {
        params: { id: selectedCompany },
      })
      .then(({ data }) => {
        dispatch(getCompanySuccess(data, "FOUND"))
      })
      .catch((error) => {
        const errorResponse = error?.response?.data
        if (
          errorResponse.status &&
          errorResponse.status.includes("forbidden")
        ) {
          dispatch(getCompanyFailure("FORBIDDEN"))
        } else {
          dispatch(getCompanyFailure("NOT_FOUND"))
        }
      })
  }

export const GetCompanyDeactivatedSettings =
  (selectedCompany: number) =>
  (dispatch: Dispatch<CompaniesActionsType>, getState: () => RootState) => {
    const state = getState()
    dispatch(GetCompanyDeactivatedSettingsAttempt())
    return axios
      .get<IncomingDeactivatedSettings>(
        `/fiduciaries/${state.fiduciary.id}/companies/${selectedCompany}/get_deactivated_settings`
      )
      .then(({ data }) => {
        const deactivatedSettings: Partial<DeactivatedSettings> = {}
        for (const key of Object.keys(data) as DeactivatedSettingsKey[]) {
          deactivatedSettings[key] = {
            text: data[key]["text"],
            value: data[key]["value"] != null,
          }
        }
        dispatch(
          GetCompanyDeactivatedSettingsSuccess({
            id: selectedCompany,
            deactivatedSettings: deactivatedSettings as DeactivatedSettings,
          })
        )
      })
      .catch(() => {
        dispatch(GetCompanyDeactivatedSettingsFailure())
      })
  }

export const updateCompanyLogoThunk =
  ({ companyId, originalFileName, file }: UpdateCompanyLogoPayload) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(companyLogoUpdateAttemptAction())
    return axios
      .post<AnyAction>(`/invoicing_logos/create_invoicing_logo`, {
        company_id: companyId,
        original_file_name: originalFileName,
      })
      .then((response) => {
        const logoId = response.data.id
        UploadFileToS3Logo({
          putUrl: response.data["put_url"],
          file,
        }).then((response) => {
          if (!response.config?.url) {
            return
          }
          dispatch(companyLogoUpdateSuccessAction(response.config.url))
          dispatch(activateCompanyLogo(companyId, logoId))
        })
      })
      .catch(() => {
        dispatch(companyLogoUpdateFailureAction())
      })
  }

export const logoDeleteThunk =
  (companyId: number, invoicing_logo_id: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(logoDeleteAttempt())
    return axios
      .post<AnyAction>(`/invoicing_logos/delete_invoicing_logo`, {
        company_id: companyId,
        invoicing_logo_id: invoicing_logo_id,
      })
      .then((response) => {
        dispatch(logoDeleteSuccess())
        dispatch(activateCompanyLogo(companyId, invoicing_logo_id))
      })
      .catch(() => {
        dispatch(logoDeleteFailure())
      })
  }

const UploadFileToS3Logo = ({
  putUrl,
  file,
}: {
  putUrl: string
  file: File
}) => {
  let config = {
    withCredentials: false,
    headers: { "Cache-Control": "public, max-age=31536000" },
  }
  return axios.put(putUrl, file, config)
}

const activateCompanyLogo =
  (companyId: number, logoId: number) =>
  (dispatch: Dispatch<RNBThunkAction>) => {
    return axios
      .post<AnyAction>(`/invoicing_logos/activate_invoicing_logo`, {
        company_id: companyId,
        invoicing_logo_id: logoId,
      })
      .then(() => {
        dispatch(getCompanyLogos())
      })
  }

export const getCompanyLogos =
  () =>
  (dispatch: Dispatch<CompaniesActionsType>, getState: () => RootState) => {
    dispatch(getCompanyLogoAttemptAction())
    return axios
      .get<CompanyLogo[]>(`/invoicing_logos/load_all_logos`, {
        params: {
          company_ids: Object.keys(getState().companies.companies).join(","),
        },
      })
      .then(({ data }) => {
        const payload = Object.fromEntries(
          data.map((logo) => [logo.company_id, logo])
        )
        dispatch(getCompanyLogoSuccessAction(payload))
      })
      .catch((e) => {
        dispatch(getCompanyLogoFailureAction(e))
      })
  }

interface saveCompanySettingsPayload {
  deactivatedSettings: DeactivatedSettings
  companyId: number
  companyName?: string | null
  hiddenFromTeam: boolean | null
  acdApiDueDate: boolean | null
  acdApiDocumentReference: boolean | null
  isFarmerCompany: boolean | null
}

export const SaveCompanySettingsThunk =
  (payload: saveCompanySettingsPayload) =>
  (
    dispatch: Dispatch<
      AnyAction | ThunkAction<Promise<void>, RootState, unknown, AnyAction>
    >,
    getState: () => RootState
  ) => {
    const fiduciary = getState().fiduciary

    let newSettings: Partial<{ [key in DeactivatedSettingEnum]: boolean }> = {}
    for (const key in payload.deactivatedSettings) {
      newSettings[key as DeactivatedSettingsKey] =
        payload.deactivatedSettings[key as DeactivatedSettingsKey].value
    }

    const oldSettings =
      getState().companies.companies[payload.companyId].deactivatedSettings
    const acdSentDataHasChanged =
      getState().companies.companies[payload.companyId].acd_api_due_date !==
        payload.acdApiDueDate ||
      getState().companies.companies[payload.companyId]
        .acd_api_document_reference !== payload.acdApiDocumentReference

    let changedSettings: Partial<DeactivatedSettings> = {}

    if (oldSettings) {
      changedSettings = differenceBetweenObjects(
        payload.deactivatedSettings,
        oldSettings
      )
    }

    let params = { newSettings, changedSettings, user_id: getState().user.id }

    let promises = []
    promises.push(
      axios
        .post<CompaniesActionsType>(`/companies/set_deactivated_settings`, {
          ...params,
          id: payload.companyId,
        })
        .then(() => {
          dispatch(saveCompanySettingsSuccess())
        })
        .catch(() => {
          dispatch(saveCompanySettingsFailure())
        })
    )
    if (payload.companyName) {
      promises.push(
        axios
          .put<CompaniesActionsType>(
            `/fiduciaries/${fiduciary.id}/companies/${payload.companyId}`,
            {
              company: {
                user_company_name: payload.companyName,
              },
            }
          )
          .then(() => {
            return
          })
      )
    }
    if (payload.hiddenFromTeam !== null) {
      promises.push(
        axios
          .put<CompaniesActionsType>(
            `/fiduciaries/${fiduciary.id}/companies/${payload.companyId}`,
            {
              hidden_from_team: payload.hiddenFromTeam,
            }
          )
          .then(() => {
            return
          })
      )
    }
    if (payload.isFarmerCompany !== null) {
      promises.push(
        axios
          .put<CompaniesActionsType>(
            `/fiduciaries/${fiduciary.id}/companies/${payload.companyId}`,
            {
              company: {
                marked_as_farmer_company: payload.isFarmerCompany,
              },
            }
          )
          .then(() => {})
      )
    }
    if (acdSentDataHasChanged) {
      promises.push(
        axios
          .put<CompaniesActionsType>(
            `/fiduciaries/${fiduciary.id}/companies/${payload.companyId}`,
            {
              acd_api_due_date: payload.acdApiDueDate,
              acd_api_document_reference: payload.acdApiDocumentReference,
            }
          )
          .then(() => {
            return
          })
      )
    }
    Promise.all(promises).then(() => {
      dispatch(GetCompanyThunk(payload.companyId))
      dispatch(GetCompanyDeactivatedSettings(payload.companyId))
    })
  }

interface updateCompanyInformationResponse {
  company: {
    company_name: string
    city: string
    postal_code: string
    address: string
    identification_number: string
    legal_form: string
    city_registration_rcs: string
    share_capital: string
    use_city_registration_rcs: boolean
  }
}
interface UpdateCompanyInformationPayload {
  companyName: string
  city: string
  postalCode: string
  address: string
  siren: string
  legalForm: CompanyLegalForm | undefined
  cityRegistrationRCS: string
  shareCapital: number | null
  useCityRegistrationRCS: boolean
  phone: string | null
  email: string | null
}

export const updateCompanyInformationThunk =
  (
    {
      companyName,
      city,
      postalCode,
      address,
      siren,
      legalForm,
      cityRegistrationRCS,
      shareCapital,
      useCityRegistrationRCS,
      phone,
      email,
    }: UpdateCompanyInformationPayload,
    companyId: number
  ) =>
  (dispatch: Dispatch<CompaniesActionsType>) => {
    dispatch(updateCompanyInformationsAttempt())
    return axios
      .put<updateCompanyInformationResponse>(`/companies/${companyId}`, {
        company: {
          user_company_name: companyName,
          city,
          address,
          postal_code: postalCode,
          identification_number: siren,
          legal_form: legalForm,
          city_registration_rcs: cityRegistrationRCS,
          share_capital: shareCapital,
          use_city_registration_rcs: useCityRegistrationRCS,
          phone,
          email,
        },
      })
      .then(() => dispatch(updateCompanyInformationsSuccess()))
      .catch(() => dispatch(updateCompanyInformationsFailure()))
  }

interface UpdateCompanyResponsePayload {
  company: {
    iban: string
    bic: string
    bank_account_owner: string
    special_mentions: string
    special_discount: number
    payment_period: number
  }
  companyId: number
}

export interface UpdateCompanyPayload {
  iban: string
  bic: string
  bankAccountOwner: string
  specialMentions: string
  specialDiscount: number
  paymentPeriod: number
}

export const updateBankSettingsThunk =
  (
    {
      iban,
      bic,
      bankAccountOwner,
      specialMentions,
      specialDiscount,
      paymentPeriod,
    }: UpdateCompanyPayload,
    companyId: number
  ) =>
  (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(updateBankSettingsAttempt())
    const fiduciary = getState().fiduciary
    return axios
      .put<UpdateCompanyResponsePayload>(
        `/fiduciaries/${fiduciary.id}/companies/${companyId}`,
        {
          company: {
            id: companyId,
            iban: iban.trim().replace(/ /g, ""),
            bic: bic.trim().replace(/ /g, ""),
            bank_account_owner: bankAccountOwner.trim(),
            special_mentions: specialMentions,
            special_discount: specialDiscount,
            payment_period: paymentPeriod,
          },
        }
      )
      .then(() => {
        dispatch(
          updateBankSettingsSuccess({
            company: {
              iban,
              bic,
              bank_account_owner: bankAccountOwner,
              special_mentions: specialMentions,
              special_discount: specialDiscount,
              payment_period: paymentPeriod,
            },
            companyId,
          })
        )
      })
      .catch(() => {
        dispatch(updateBankSettingsFailure())
      })
  }

export const updateDisabledCentralizedCodesThunk =
  (companyId: number, disable_centralized_codes: boolean, userId: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(updateDisabledCentralizedCodesAttempt())
    return axios
      .post(`/companies/update_disable_centralized_codes`, {
        company_id: companyId,
        disable_centralized_codes: disable_centralized_codes,
        user_id: userId,
      })
      .then(() => {
        dispatch(
          updateDisabledCentralizedCodesSuccess(
            companyId,
            disable_centralized_codes
          )
        )
        dispatch(GetCompanyThunk(companyId))
      })
      .catch(() => {
        dispatch(updateDisabledCentralizedCodesFailure())
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
  }

export const updateAcdSentDataThunk =
  ({
    companyId,
    acdApiDueDate,
    acdApiDocumentReference,
  }: {
    companyId: number
    acdApiDueDate: boolean | null
    acdApiDocumentReference: boolean | null
  }) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(updateAcdSentDataAttemptAction())
    return axios
      .post(`/companies/update_acd_sent_data`, {
        company_id: companyId,
        acd_api_due_date: acdApiDueDate || false,
        acd_api_document_reference: acdApiDocumentReference || false,
      })
      .then(() => {
        dispatch(
          updateAcdSentDataSuccessAction({
            companyId: companyId,
            acdApiDueDate: acdApiDueDate || false,
            acdApiDocumentReference: acdApiDocumentReference || false,
          })
        )
      })
      .catch(() => {
        dispatch(updateAcdSentDataErrorAction())
      })
  }

export const UpdateAccountingSoftwareThunk =
  (
    companyId: number,
    newSoftware: string,
    prefixBuy: string,
    prefixSell: string,
    reference: string,
    codesList: { id: number; value: string }[] | [],
    accountsList: { id: number; value: string }[] | []
  ) =>
  (
    dispatch: Dispatch<
      CompaniesActionsType | RNBThunkAction | CompanySettingsActionsType
    >
  ) => {
    dispatch(UpdateAccountingSoftwareAttempt())
    return axios
      .post(`/companies/update_accounts_and_codes_and_software_change`, {
        id: companyId,
        accounting_software: newSoftware,
        buy_auxiliary_prefix: prefixBuy,
        sell_auxiliary_prefix: prefixSell,
        accounting_software_reference: reference,
        codes_list: codesList,
        accounts_list: accountsList,
      })
      .then(() => {
        dispatch(
          UpdateAccountingSoftwareSuccess(
            companyId,
            newSoftware,
            prefixBuy,
            prefixSell,
            reference
          )
        )
        dispatch(GetCompanyThunk(companyId))
        dispatch(GetWritingLabelDataThunk(companyId))
      })
      .catch(() => {
        dispatch(UpdateAccountingSoftwareFailure())
      })
  }

export const SetIsFavoriteThunk =
  (companyId: number, is_favorite: boolean) =>
  (dispatch: Dispatch<CompaniesActionsType>) => {
    dispatch(SetIsFavoriteAttempt())
    return axios
      .post(`/companies/set_company_favorites`, {
        id: companyId,
        is_favorite: is_favorite,
      })
      .then(() => dispatch(SetIsFavoriteSuccess(companyId, is_favorite)))
      .catch(() => dispatch(SetIsFavoriteFailure()))
  }
