/* eslint-disable camelcase */
import styled from "styled-components/macro"
import { Color, OpacityColor, opacityColors } from "../../styles/design.config"

import { ApiImportStatus } from "../../store/ducks/writings.ducks"
import { Text } from "./Text"
import { useIntl } from "react-intl"

import { ReactComponent as Warning } from "../../assets/warning.svg"
import { Spacer } from "ldlj"

export interface ApiStatusProps {
  status: ApiImportStatus | null
  errorMessage: string
}

export const ApiStatus = ({ status, errorMessage }: ApiStatusProps) => {
  const intl = useIntl()
  const colorByStatus: Record<ApiImportStatus, Color> = {
    imported: "cornflower",
    in_progress: "orange",
    import_error: "amaranth",
  }
  const backgroundColorByStatus: Record<ApiImportStatus, OpacityColor> = {
    imported: "cornflower",
    in_progress: "orange",
    import_error: "amaranth",
  }

  if (status === null) {
    return null
  }

  return (
    <StyledContainer>
      <StyledStatusBadge backgroundColor={backgroundColorByStatus[status]}>
        <Text
          text={intl.formatMessage({ id: `api-status.${status}` })}
          textStyle={{
            color: colorByStatus[status],
            fontWeight: 500,
            fontSize: 1.75,
          }}
        />
      </StyledStatusBadge>
      <Spacer width={0.5} />
      {errorMessage ? (
        <TextContainer>
          <StyledWarning />
          <Spacer width={0.5} />
          <Text
            text={errorMessage}
            textStyle={{
              color: "amaranth",
              fontSize: 1.5,
            }}
          />
        </TextContainer>
      ) : null}
    </StyledContainer>
  )
}

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  justify-content: center;
`
const StyledWarning = styled(Warning)`
  height: 2.5rem;
  width: 2.5rem;
`

const StyledStatusBadge = styled.div<{ backgroundColor: OpacityColor }>`
  background-color: ${({ backgroundColor }) => opacityColors[backgroundColor]};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
